import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/user/manage",
    name: "ContentLayout",
    component: () => import("@/views/ContentLayout/ContentLayoutViews"),
    children: [
      {
        path: "/user/manage",
        name: "UserManage",
        meta: { key: "UserManage" },
        component: () => import("@/Container/UserManage/UserManagePage"),
      },
      {
        path: "/package/manage",
        name: "PackageManage",
        meta: { key: "PackageManage" },
        component: () => import("@/Container/PackageManage/PackageManagePage"),
      },
      {
        path: "/package/item",
        name: "PackageItem",
        meta: { key: "PackageManage" },
        component: () => import("@/Container/PackageItem/packageItemPage"),
      },
      {
        path: "/package/update",
        name: "PackageUpdate",
        meta: { key: "" },
        component: () => import("@/Container/PackageUpdate/PackageUpdatePage"),
      },
      {
        path: "/order/manage",
        name: "OrderManage",
        meta: { key: "OrderManage" },
        component: () => import("@/Container/OrderManage/OrderManagePage"),
      },
      {
        path: "/order/detail",
        name: "OrderDetail",
        meta: { key: "OrderManage" },
        component: () => import("@/Container/OrderDetail/OrderDetailPage"),
      },
      {
        path: "/order/pack",
        name: "OderPack",
        meta: { key: "OrderManage" },
        component: () => import("@/Container/OrderPack/OrderPackPage"),
      },
      {
        path: "/action/manage",
        name: "ActionManage",
        meta: { key: "ActionManage" },
        component: () => import("@/Container/ActionManage/ActionManagePage"),
      },
      {
        path: "/depository/manage",
        name: "DepositoryManage",
        meta: { key: "DepositoryManage" },
        component: () =>
          import("@/Container/DepositoryManage/DepositoryManagePage"),
      },
      {
        path: "/coupon/manage",
        name: "CouponManage",
        meta: { key: "CouponManage" },
        component: () => import("@/Container/CouponManage/CouponManagePage"),
      },
      {
        path: "/coupon/item",
        name: "CouponItem",
        meta: { key: "CouponManage" },
        component: () => import("@/Container/CouponItem/CouponItemPage"),
      },
      {
        path: "/agreement/manage",
        name: "AgreementManage",
        meta: { key: "AgreementManage" },
        component: () =>
          import("@/Container/AgreementManage/AgreementManagePage"),
      },
      {
        path: "/agreement/item",
        name: "AgreementItem",
        meta: { key: "AgreementManage" },
        component: () => import("@/Container/AgreementItem/AgreementItemPage"),
      },
      {
        path: "/config/manage",
        name: "ConfigManage",
        meta: { key: "ConfigManage" },
        component: () => import("@/Container/ConfigManage/ConfigManagePage"),
      },
      {
        path: "/news/manage",
        name: "NewsManage",
        meta: { key: "NewsManage" },
        component: () => import("@/Container/NewsManage/NewsManagePage"),
      },
      {
        path: "/news/item",
        name: "NewsItem",
        meta: { key: "NewsManage" },
        component: () => import("@/Container/NewsItem/NewsItemPage"),
      },
      {
        path: "/material/manage",
        name: "MaterialManage",
        meta: { key: "MaterialManage" },
        component: () =>
          import("@/Container/MaterialManage/MaterialManagePage"),
      },
      {
        path: "/admin/manage",
        name: "AdminManage",
        meta: { key: "AdminManage" },
        component: () => import("@/Container/AdminManage/AdminManagePage"),
      },
      {
        path: "/price/manage",
        name: "PriceManage",
        meta: { key: "PriceManage" },
        component: () => import("@/Container/PriceManage/PriceManagePag"),
      },
      {
        path: "/pay/manage",
        name: "PayManage",
        meta: { key: "PayManage" },
        component: () => import("@/Container/PayManage/PayManagePage"),
      },
    ],
  },
  {
    path: "/login",
    name: "LoginPage",
    component: () => import("@/views/Login/LoginPage"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
