import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd, { message } from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import { getToken } from "@/store/cookies";

Vue.config.productionTip = false;

Vue.use(Antd);

router.beforeEach((to, from, next) => {
  const token = getToken();
  if (token === "") {
    if (to.name !== "LoginPage") {
      if (from.name === "LoginPage") {
        return;
      }
      message.error("请先登录");
      const history = router.history || {};
      const current = history.current || {};
      const { name, params, query } = current;
      router.replace({
        path: "/login",
        params: {
          name,
          params,
          query,
        },
      });
      return;
    }
  }
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
