import VueCookie from "vue-cookies";

const TOKEN = "AdminToken";
const NICK = "AdminName";
const UID = "AdminUID";

export function getToken() {
  return VueCookie.get(TOKEN) || "";
}

export function getNick() {
  return VueCookie.get(NICK) || "";
}

export function setToken(token, expireTs = 86400) {
  const expire = { expires: `${expireTs}s` };
  VueCookie.set(TOKEN, token, expire);
}

export function setNick(nick, expireTs = 86400) {
  const expire = { expires: `${expireTs}s` };
  VueCookie.set(NICK, nick, expire);
}

export function setUid(uid, expireTs = 86400) {
  const expire = { expires: `${expireTs}s` };
  VueCookie.set(UID, uid, expire);
}

export function getUid() {
  return VueCookie.get(UID) || "";
}

export function setLoginInfo(token, uid, nick, expireTs = 86400) {
  setUid(uid, expireTs);
  setToken(token, expireTs);
  setNick(nick, expireTs);
}

export function deleteLoginInfo() {
  VueCookie.remove(UID);
  VueCookie.remove(TOKEN);
  VueCookie.remove(NICK);
}

export function setPowerList(powerList, expireTs = 86400) {
  const expire = { expires: `${expireTs}s` };
  VueCookie.set("power_list", powerList, expire);
}

export function getPowerList() {
  return VueCookie.get("power_list") || "";
}

export function setAdminRole(adminRole, expireTs = 86400) {
  const expire = { expires: `${expireTs}s` };
  VueCookie.set("admin_role", adminRole, expire);
}

export function getAdminRole() {
  return VueCookie.get("admin_role") || "";
}
